export function get_user_agent () {
  if (typeof navigator === 'undefined') return '';

  return navigator.userAgent;
}

export function localstorage_set (key: string, content: object) {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem(key, JSON.stringify(content));  
}

export function localstorage_get (key: string): object {
  if (typeof localStorage === 'undefined') return {};

  try {
    return JSON.parse(localStorage.getItem(key) || '');
  } catch (e) {
    return {};
  }
}