//import {decompress} from 'brotli';
import {get_camera_list, CameraOptions, download_txt} from "mt_utils.ts/common";
import {brotliDecode} from '../lib/decode.ts';
import {get_user_agent, localstorage_set, localstorage_get} from "./utils.ts";
import {BeforeInstallPromptEvent, SwMsg} from "./declares.ts";

declare global {
	interface Window {
		deferredPrompt: Event | null;
		get_user_agent: () => string;
		sw_skip_waiting: () => void;
		sw_version: () => void;
		pwa_install: () => Promise<void>;
		assignDotNetLoadHelper: (arg0: any) => void;
		localstorage_set: (arg0: string, arg1: object) => void;
		localstorage_get: (arg0: string) => object;
		get_camera_list: () => Promise<CameraOptions[]>;
		download_txt: (arg0: string, arg1?: string) => void;
		Blazor: {
			start: (
				arg0: {
					loadBootResource: 
						(type: string, name: string, defaultUri: string, integrity: string) => Promise<Response> | undefined
				}) => void;
		};
	}
}

let swReg: ServiceWorkerRegistration;
let dotNetHelper: any;
let is_sw_waiting = false;
let is_installed = true;

if ("serviceWorker" in navigator) {
	navigator.serviceWorker.register('sw.js', { scope: './' })
		.then((registration) => {
			swReg = registration;
			//let serviceWorker;
			if (registration.installing) {
				//serviceWorker = registration.installing;
				console.log('SW: installing.');
				//document.querySelector("#kind").textContent = "installing";
			} else if (registration.waiting) {
				//serviceWorker = registration.waiting;
				console.log('SW: waiting.');
				is_sw_waiting = true;
				//document.querySelector("#kind").textContent = "waiting";
			} else if (registration.active) {
				//serviceWorker = registration.active;
				console.log('SW: active.');
				//document.querySelector("#kind").textContent = "active";
			}
			
			if (swReg.active) {
				swReg.active.onstatechange = (e) => {
					console.log('sw state change: ', e.target);
				};

				swReg.active.onerror = (e) => {
					console.log('sw error: ', e);
				};
			}
			
			// if (serviceWorker) {
			// 	// logState(serviceWorker.state);
			// 	serviceWorker.addEventListener("statechange", (e) => {
			// 		// logState(e.target.state);
			// 		console.log('state change: ', e.target);
			// 	});
			// }
		})
		.catch((error) => {
			// Something went wrong during registration. The service-worker.js file
			// might be unavailable or contain a syntax error.
			console.error(error);
		});

	navigator.serviceWorker.onmessage = async (event) => {
		console.log('Client Received message: ', event.data);
		
		if ((event.data as SwMsg).type == 'waiting')
		{
			await dotNetHelper.invokeMethodAsync('SwWaiting', true);
		}
		else if ((event.data as SwMsg).type == 'sw_version')
		{
			await dotNetHelper.invokeMethodAsync('SwVersion', (event.data as SwMsg).data);
		}
		else if ((event.data as SwMsg).type == 'activated')
		{
			//window.location.reload();
		}
	};
} else {
	// The current browser doesn't support service workers.
	// Perhaps it is too old or we are not in a Secure Context.
	console.warn("The current browser doesn't support service workers.");
}

window.Blazor.start({
	loadBootResource: function (type: string, name: string, defaultUri: string, integrity: string) {
		console.log(name, defaultUri, integrity);
		if (type !== 'dotnetjs' && location.hostname !== 'localhost' && type !== 'configuration' && !location.hostname.startsWith('192.168.')) {
			return (async function () {
				const response = await fetch(defaultUri + '.br', {cache: 'no-cache'});
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				const originalResponseBuffer = await response.arrayBuffer();
				// const originalResponseArray = new Uint8Array(originalResponseBuffer);
				// const decompressedResponseArray = decompress(originalResponseArray as Buffer);
				const originalResponseArray = new Int8Array(originalResponseBuffer);
				const decompressedResponseArray = brotliDecode(originalResponseArray);
				const contentType = type ===
				'dotnetwasm' ? 'application/wasm' : 'application/octet-stream';

				return new Response(decompressedResponseArray,
					{headers: {'content-type': contentType}} as ResponseInit);
			})();
		}
	}
});

function sw_skip_waiting () {
	window.location.reload();
	//if (swReg?.active) swReg.active.postMessage('skip_waiting');
}

function sw_version () {	
	if (swReg?.active) swReg.active.postMessage({ type: 'sw_version' });
}

function assignDotNetLoadHelper (obj: any) {
	//console.log(obj);
	dotNetHelper = obj;
	
	if (is_sw_waiting) dotNetHelper.invokeMethodAsync('SwWaiting', true);
	dotNetHelper.invokeMethodAsync('PwaInstalled', is_installed);
	sw_version();
	//console.log(dotNetHelper);
	//await dotNetHelper.invokeMethodAsync('TestCall', obj);
	//await dotNetHelper.invokeMethodAsync('TestCall', {nInit: 1, bInit: true, sInit: "finished"});
}

async function pwa_install () {
	// Hide the app provided install promotion
	// hideInstallPromotion();
	// Show the install prompt

	(window.deferredPrompt as BeforeInstallPromptEvent).prompt();
	// Wait for the user to respond to the prompt
	const { outcome } = await (window.deferredPrompt as BeforeInstallPromptEvent).userChoice;
	// Optionally, send analytics event with outcome of user choice
	console.log(`User response to the install prompt: ${outcome}`);
	// We've used the prompt, and can't use it again, throw it away
	is_installed = true;
	window.deferredPrompt = null;
}

// PWA listens
window.addEventListener('beforeinstallprompt', (event) => {
	// Prevent the mini-infobar from appearing on mobile
	//event.preventDefault();

	// Prevent the mini-infobar from appearing on mobile
	window.deferredPrompt = event;
	is_installed = false;

	console.log('👍', 'beforeinstallprompt', event);
});

window.addEventListener('appinstalled', () => {
	// Hide the app-provided install promotion
	//hideInstallPromotion();
	// Clear the deferredPrompt so it can be garbage collected
	window.deferredPrompt = null;
	is_installed = true;
	// Optionally, send analytics event to indicate successful install
	console.log('PWA was installed');
});

window.get_user_agent = get_user_agent;
window.localstorage_set = localstorage_set;
window.localstorage_get = localstorage_get;
window.get_camera_list = get_camera_list;
window.sw_skip_waiting = sw_skip_waiting;
window.pwa_install = pwa_install;
window.sw_version = sw_version;
window.download_txt = download_txt;
window.assignDotNetLoadHelper = assignDotNetLoadHelper;